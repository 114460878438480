<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  <HomePage/>-->
  <router-view></router-view>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import HomePage from './components/HomePage'
export default {
  name: 'App',
  components: {

    //HomePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width:100%;
  min-height: 100%;
  background-color: #d3d3d3;
}
</style>
