<template>
  <div id="innercontainer" style="width:98%;height:100%;margin:0 auto">
      <div id="Welcome" align="left" >
          <p style="font-size:25px;margin-top:10px;">2024</p>

            <p style="font-size:17px;margin-top:-10px;">
              Matthew Faytak, Mariana Quintana Godoy, <strong>Tianle Yang</strong>. (2024). "Lingual and epilaryngeal articulation of vowels in Mundabli."
              ISSP 2024 - 13th International Seminar on Speech Production.
              <a href='/Mundabli_proceeding_ISSP.pdf'>[inproceedings]</a>
          </p>

            <p style="font-size:17px;margin-top:-10px;">
              Phil Rose & <strong>Tianle Yang</strong>. (2024). "Diagnosing dominance: Problematic sandhi types in the Chinese Wu dialect of Jinshan."
              Proceedings of the Linguistic Society of America.
              <a href='https://journals.linguisticsociety.org/proceedings/index.php/PLSA/article/view/5667'>[inproceedings]</a>
          </p>

          <p style="font-size:17px;margin-top:-10px;">
              <strong>Tianle Yang</strong>. (2024). "Acoustic analysis of Mundabli tones in monosyllabic words."
              First annual meeting of PHUNY (Phonetics/Phonology in Upstate NY).
              <a>[talk]</a>
          </p>

          <p style="font-size:17px;margin-top:-10px;">
             <strong>Tianle Yang</strong> & Phil Rose. (2024). "Left dominant or right dominant? Problematic sandhi types in the Chinese Wu dialect of Jinshan."
              2024 LSA Annual Meeting.
              <a href='/Jinshan_dominance_abstract.pdf'>[abstract]</a> and <a href='/dominance_poster_LSA.pdf'>[poster]</a>
          </p>

          <p style="font-size:25px;margin-top:10px;">2022</p>
          <p style="font-size:17px;margin-top:-10px;">
              Phil Rose & <strong>Tianle Yang</strong>. (2022). "Modeling Interaction between Tone and Phonation Type in the Northern Wu Dialect of Jinshan."
              18th Australasian International Conference on Speech Science and Technology.
              <a href='/Jinshan_SST.pdf'>[inproceedings]</a>
          </p>

          <p style="font-size:17px;margin-top:-10px;">
              <strong>Tianle Yang</strong>. (2022). "Neural networks with different initialization methods for depression detection."
              <a href='https://arxiv.org/pdf/2205.04792.pdf'>[preprint]</a>
          </p>

      </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeComponent',
    methods:{

    }
  }
</script>